import {
	userConstants
}from '../constants/'
import {
	defAction
}from '../../helpers/defaultAction'
import agent from '../../api/agent'
import history from '../../helpers/history'

export const userActions = {
	loginPasswordAndUserName,
	loginPhoneNumberCode,
	RefreshPageloginPhoneNumberCode,
	logout,
	refreshUserData,
	registerResidentRB,
	clearFirstEnter
}

function loginPasswordAndUserName(values){
	const dispatchObj = {
		constants : userConstants.Login,
		service   : {
			func   : agent.User.loginPasswordAndUserName,
			params : values
		}

		// sucFunction: () => {
		// 	history.push('/login/smsConfirm')
		// }
	}
	return defAction(dispatchObj)
}

function loginPhoneNumberCode(values){
	const dispatchObj = {
		constants : userConstants.Login,
		service   : {
			func   : agent.User.loginPhoneNumberCode,
			params : values
		},
		sucFunction: () => {
			history.push('/account')
		}
	}
	return defAction(dispatchObj)
}

function RefreshPageloginPhoneNumberCode(values){
	const dispatchObj = {
		constants : userConstants.Login,
		service   : {
			func   : agent.User.refreshPageloginPhoneNumberCode,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function registerResidentRB(values){
	const dispatchObj = {
		constants : userConstants.Register,
		service   : {
			func   : agent.User.registerResidentRB,
			params : values
		},
		sucFunction: () => {
			history.push('/login')
		}
	}
	return defAction(dispatchObj)
}

function logout(){
	const jsonUser = window.localStorage.getItem('user')
	const sessionId = JSON.parse(jsonUser).sessionId
	const dispatchObj = {
		constants   : userConstants.Logout,
		sucFunction : (url) => {
			history.push('/')
			window.localStorage.removeItem("idToken")
			if (!(url === false || url === true || url === undefined || url === null || url === ""))
				window.location.href = url
		},
		service: {
			func   : agent.User.logout,
			params : {
				sessionId: sessionId
			}
		}
	}
	return defAction(dispatchObj)
}

function refreshUserData(){
	const dispatchObj = {
		constants : userConstants.RefreshUserData,
		service   : {
			func: agent.User.refreshUserData
		}
	}
	return defAction(dispatchObj)
}

const clearFirstEnter = () => {
	return (dispatch) => dispatch({ type: userConstants.clearFirstEnter.CLEAR })
}
