import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from "../components/Loading/Global"

const LoadableAisMVMessage = Loadable({
	loader  : () => import('../containers/AisMV'),
	loading : () => <Loading />
})
const LoadableAisMVNotDirected = Loadable({
	loader  : () => import('../containers/AisMV/AisMVNotDirected'),
	loading : () => <Loading />
})

export const AisMVLoadable = {
	LoadableAisMVMessage,
	LoadableAisMVNotDirected
}