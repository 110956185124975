import React, {
	useEffect
}from 'react'

import {
	getPageReAbonentsAISMV,
	addReAbonentsAISMV,
	updateReAbonentsAISMV,
	deleteReAbonentsAISMV,
	restoreReAbonentsAISMV
}from './actions'

import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reAbonentsAISMV = () => {
	const dispatch = useDispatch()

	const { reAbonentsAISMVList, isLoading, changed } = useSelector(state => state.reAbonentsAISMVReducer)

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	useEffect(() => {
		if (changed)
			onPaginationChange(1)
	}, [changed])

	const columns = [
		{
			title     : 'Код СОАТО',
			inputType : 'text',
			dataIndex : 'soato',
			editable  : false,
			key       : 'soato',
			width     : 100
		},
		{
			title     : 'УНП',
			inputType : 'text',
			dataIndex : 'unp',
			editable  : false,
			key       : 'unp'
		},
		{
			title     : 'Полное наименование',
			inputType : 'text',
			dataIndex : 'fullName',
			editable  : false,
			key       : 'fullName'
		},
		{
			title     : 'Краткое наименование',
			inputType : 'text',
			dataIndex : 'abbreviatedName',
			editable  : false,
			key       : 'abbreviatedName'
		},
		{
			title     : 'Статус',
			inputType : 'text',
			dataIndex : 'subscriberStatus',
			editable  : false,
			key       : 'subscriberStatus'
		},
		{
			title     : 'Дата регистрации',
			inputType : 'text',
			dataIndex : 'registrationDate',
			editable  : false,
			key       : 'registrationDate'
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReAbonentsAISMV({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				addItem={ addReAbonentsAISMV }
				changed={ changed }
				columns={ columns }
				dataSource={ reAbonentsAISMVList }
				deleteItem={ deleteReAbonentsAISMV }
				isEditable={ false }
				isLoading={ isLoading }
				restoreItem={ restoreReAbonentsAISMV }
				updateItem={ updateReAbonentsAISMV }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reAbonentsAISMV