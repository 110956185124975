import React, {
	useState
}from "react"
import './styles.css'
import CustomMenu from '../../components/CustomMenu'
import routes from './routes'
import routesShort from './routesShort'
import {
	Link
}from 'react-router-dom'
import "antd/lib/radio/style/index.css"
import {
	useSelector
}from "react-redux"
const NSI = () => {
	const { roles } = useSelector(state => state.userReducer)
	const swtitchRoute = () => {
		if (roles.includes('authorizedUnit')||roles.includes('operatorISEWORD'))
			return routesShort
		if (roles.includes('administratorSystem'))
			return routes
	}
	const [currentItem, setCurrentItem] = useState(0)
	const Element = swtitchRoute()[currentItem].render
	return (<>

		<div className="nsi-menu">

			<CustomMenu
				defaultSelectedKey={ swtitchRoute()[1] }
				selectedKey={ currentItem }
			>
				{ swtitchRoute().map((elem, index) => {
					return (<Link
						key={ index }
						to="#"
						onClick={ () => setCurrentItem(index) }
					        >
						<li>
							<div>
								<span>
									{ elem.text }
								</span>
							</div>
						</li>
					</Link>)
				}) }
			</CustomMenu>
			<div className="menu-element">
				<Element />
			</div>
		</div>
	</>
	)
}
export default NSI