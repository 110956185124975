import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReTypesDocumentsSED/getByName', values),
	add     : (values) => requests.post('/ReTypesDocumentsSED/createTypesDocumentsSED', values),
	update  : (values) => requests.post('/ReTypesDocumentsSED/updateTypesDocumentsSED', values),
	delete  : (values) => requests.post('/ReTypesDocumentsSED/deleteTypesDocumentsSED', values),
	restore : (values) => requests.post('/ReTypesDocumentsSED/restoreTypesDocumentsSED', values)
}
export default api