import React, {
	useEffect
}from 'react'

import {
	getPageReIndex
}from './actions'
import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reIndex = () => {
	const dispatch = useDispatch()
	const { reIndexList, isLoading } = useSelector(state => state.reIndexReducer)

	const reRegion =(text, record) => {
		return `${ record.administrativeTerritory.reRegion.name }`
	}

	const getDistrict=(text, record) => {
		return `${ record.administrativeTerritory.reDistrict.name }`
	}

	const getAdministrativeTerritoryName=(text, record) => {
		return `${ record.administrativeTerritory.name }`
	}

	const getStreet =(text, record) => {
		return `${ record.street?record.street.reAddressType.nameShort:'' }${ record.street?record.street.name:'' }`
	}

	const getBuildings =(text, record) => {
		return `${ record.buildings }`
	}

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	const columns = [
		{
			title     : 'Индекс',
			inputType : 'text',
			dataIndex : 'index',
			editable  : false,
			key       : 'index',
			width     : 100
		},
		{
			title     : 'Регион',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			render    : reRegion
		},
		{
			title     : 'Район',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			render    : getDistrict
		},
		{
			title     : 'Населенный пункт',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			render    : getAdministrativeTerritoryName
		},
		{
			title     : 'Улица',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			render    : getStreet
		},
		{
			title     : 'Номера домов',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			width     : 100,
			render    : getBuildings
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReIndex({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				columns={ columns }
				dataSource={ reIndexList }
				isEditable={ false }
				isLoading={ isLoading }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reIndex