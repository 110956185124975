import React, {
	useEffect
}from 'react'

import {
	getPageReOfficialPersons,
	addReOfficialPersons,
	updateReOfficialPersons,
	deleteReOfficialPersons,
	restoreReOfficialPersons
}from './actions'
import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const ReOfficialPersons = () => {
	const dispatch = useDispatch()
	const { reOfficialPersonsList, isLoading, changed } = useSelector(state => state.reOfficialPersonsReducer)
	const getDate=(text, record) => {
		return text ? moment(text).format('DD-MM-YYYY'):''
	}

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	useEffect(() => {
		if (changed)
			onPaginationChange(1)
	}, [changed])

	const columns = [
		{
			title     : 'ФИО',
			inputType : 'text',
			dataIndex : 'fullName',
			editable  : false,
			key       : 'fullName',
			width     : 200
		},
		{
			title     : 'Должность',
			inputType : 'text',
			dataIndex : 'position',
			editable  : false,
			key       : 'position'
		},
		{
			title     : 'Дата начала действия',
			inputType : 'text',
			dataIndex : 'startDate',
			editable  : false,
			key       : 'startDate',
			render    : getDate
		},
		{
			title     : 'Дата окончания действия',
			inputType : 'text',
			dataIndex : 'endDate',
			editable  : false,
			key       : 'endDate',
			render    : getDate
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReOfficialPersons({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				addItem={ addReOfficialPersons }
				changed={ changed }
				columns={ columns }
				dataSource={ reOfficialPersonsList }
				deleteItem={ deleteReOfficialPersons }
				isLoading={ isLoading }
				nameTable=" должностное лицо"
				restoreItem={ restoreReOfficialPersons }
				updateItem={ updateReOfficialPersons }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default ReOfficialPersons