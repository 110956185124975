import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from "../components/Loading/Global"

const LoadableUsers = Loadable({
	loader  : () => import('../containers/Users'),
	loading : () => <Loading />
})

const LoadableOneUser = Loadable({
	loader  : () => import('../containers/Users/OneUser/index'),
	loading : () => <Loading />
})
const LoadableAuth = Loadable({
	loader  : () => import('../containers/Users/FirstAuth/index'),
	loading : () => <Loading />
})

export const managmentLoadable = {
	LoadableUsers,
	LoadableOneUser,
	LoadableAuth
}