import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from "../components/Loading/Global"

const LoadableDocuments = Loadable({
	loader  : () => import('../containers/Documents'),
	loading : () => <Loading />
})
const LoadableDocumentView = Loadable({
	loader  : () => import('../containers/Documents/DocumentView'),
	loading : () => <Loading />
})

export const documentLoadable = {
	LoadableDocuments,
	LoadableDocumentView
}