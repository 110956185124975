import Notifications from '../../../helpers/Notifications'
import "./style.css"
import React, {
	useEffect,
	useState
}from "react"
import {
	Form, Input, Modal, Pagination, Popconfirm, Select, Table
}from "antd"
import {
	useDispatch,
	useSelector
}from "react-redux"
import {
	deleteIcon, editIcon, restoreIcon
}from "./SVG"
import CustomButton from "../../../components/ButtonCustom"

const NSITable = (props) => {
	const { roles } = useSelector(state => state.userReducer)
	const { columns, nameTable, dataSource, className = '', onPaginationChange, isShowModal = true, isEditable = true, addItem,
	 deleteItem, restoreItem, updateItem, changed, isLoading, ...otherProps } = props

	const [form] = Form.useForm()
	const dispatch = useDispatch()

	const [visible, setVisible] = useState(false)
	const [editRecord, setEditRecord] = useState(null)
	const expandedColumns = [...columns]
	const isAdmin = roles.includes('administratorSystem')
	const operatorISEWORD = roles.includes('operatorISEWORD')
	const authorizedUnit = roles.includes('authorizedUnit')

	 useEffect(() => {
		if (changed){
			Notifications.successNotice(changed, '', 15)
			closeForm()
		}
	 }, [changed])

	 useEffect(() => {
		 form.setFieldsValue({ ...editRecord })
	 }, [editRecord])

	const onEditClick = (record) => {
		setVisible(true)
		setEditRecord(record)
	}

	const closeForm = () => {
		setVisible(false)
		setEditRecord(null)
		form.resetFields()
	}

	const filteredItem = (item) => {
		return (item.dataIndex !== 'startDate'
                && item.dataIndex !== 'endDate'
                && !item.isNotEditable)
	}

	if ((isAdmin || operatorISEWORD || authorizedUnit) && (isEditable)){
		expandedColumns.push({
			dataIndex     : 'change',
			key           : 'update',
			width         : 100,
			isNotEditable : true,
			render        : (id, record) => (
				<>
					<div className="change__column">
						{ editIcon(() => onEditClick(record)) }
						<Popconfirm
							cancelText="Нет"
							okText="Да"
							okType="primary"
							title={ `Вы действительно хотите ${ record.isDeleted ? 'восстановить' : 'удалить' } запись` }
							onCancel={ (e) => {
								e.stopPropagation()
								return null
							} }
							onConfirm={ (e) => {
								e.stopPropagation()
								record.isDeleted
									? dispatch(restoreItem({ id: record.id }))
									: dispatch(deleteItem({ id: record.id }))
							} }
						>
							{ !record.isDeleted ? deleteIcon : restoreIcon }
						</Popconfirm>
					</div>
				</>
			)
		})
	}

	const okHandle =() => {
		form.validateFields().then((values) => {
			if (editRecord){
				let isChanged = false
				for (const key in values){
					if (values[key] != editRecord[key])
						isChanged= true
				}
				if (isChanged){
					dispatch(updateItem({ id: editRecord.id,
						...values }))
				}

				else
					Notifications.warningNotice('Изменений в записе не обнаружено!', '', 15)
			}
			else
				dispatch(addItem(values))
		})
	}

	return (
		<>
			{ isShowModal && isEditable && <CustomButton onClick={ () => setVisible(true) }>
Добавить
			</CustomButton> }
			<Table
				className={ `nsi__table ${ className }` }
				columns={ expandedColumns }
				dataSource={ dataSource ? dataSource.items : null }
				loading={ isLoading }
				pagination={ false }
				{ ...otherProps }
			>
			</Table>
			{ dataSource?.count > 10 && <Pagination
				showQuickJumper
				className="nsi__pagination"
				current={ dataSource?.page }
				pageSize={ dataSource?.pageSize }
				showSizeChanger={ false }
				total={ dataSource?.count }
				onChange={ onPaginationChange }
			                            ></Pagination> }
			{ isShowModal && isEditable &&
			<Modal
				destroyOnClose
				open={ visible }
				width={ 924 }
				onCancel={ closeForm }
				onOk={ okHandle }
			>
				<p className="TitleNSIModal">
					{ editRecord ? "Редактировать" : "Добавить" }
					{ nameTable }
				</p>
				<Form
					form={ form }
					labelAlign="left"
					labelCol={ { span: 6 } }
				>
					{ columns.filter(filteredItem).map(item => {
						if (item.isSelect){
							return (<Form.Item
								key={ item?.id }
								label={ item.title }
								name={ item.dataIndex }
								rules={ item?.rules }
							        >
								<Select>
									{ item?.values?.map((opt) => {
										return (<Select.Option
											key={ opt.id }
											value={ opt.id }
										        >
											{ opt.name }
										</Select.Option>)
									}) }
								</Select>
							</Form.Item>)
						}
						return (<Form.Item
							key={ item?.id }
							label={ item.title }
							name={ item.dataIndex }
							rules={ item?.rules }

						        >
							<Input
								onPressEnter={ okHandle }
							></Input>
						</Form.Item>)
					}) }
				</Form>
			</Modal> }
		</>)
}

export default NSITable