import {
	requests
}from "../../../api/agent"

const api = {
	getByName : (values) => requests.getWithParams('/ReTypesDocuments/getByName', values),
	get 	: (values) => requests.getWithParams('/ReTypesDocuments/getAllPaged', values),
	add     : (values) => requests.post('/ReTypesDocuments/createTypesDocuments', values),
	update  : (values) => requests.post('/ReTypesDocuments/updateTypesDocuments', values),
	delete  : (values) => requests.post('/ReTypesDocuments/deleteTypesDocuments', values),
	restore : (values) => requests.post('/ReTypesDocuments/restoreTypesDocuments', values)
}
export default api