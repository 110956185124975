import {
	createSlice
}from "@reduxjs/toolkit"

export const usersSlice = createSlice({
	name         : "users",
	initialState : {
		isLoading        : false,
		error            : null,
		usersList        : null,
		changed          : null,
		oneUser          : null,
		usersOrgList    	: null,
		commissionerList : null,
		userHistoryList	 : null
	},
	reducers: {
		setLoading: (state) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.isLoading = false,
			state.error = action.payload
		},
		getUsersSuccess: (state, action) => {
			state.isLoading = false,
			state.usersList = action.payload,
			state.changed = null
		},
		getUserHistoryListSuccess: (state, action) => {
			state.isLoading = false,
			state.userHistoryList = action.payload
		},
		getCommissionerSuccsess: (state, action) => {
			state.isLoading = false,
			state.commissionerList = action.payload,
			state.changed = null
		},
		editSuccess: (state, action) => {
			state.isLoading = false,
			state.edited = action.payload ? 'Изменения сохранены' : null
		},
		getOrgUsersSuccess: (state, action) => {
			state.isLoading = false,
			state.usersOrgList = action.payload,
			state.changed = null
		},
		clearOrgUsers: (state, action) => {
			state.usersOrgList =null,
			state.changed = null
		},
		responseSuccsessBlockUser: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Пользователь заблокирован' : null
		},
		responseSuccsessUnblockUser: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Пользователь разблокирован' : null
		},
		responseSuccsessUser: (state, action) => {
			state.isLoading = false
			state.oneUser = action.payload
		},
		clearUser: (state, action) => {
			state.isLoading = false
			state.oneUser = null
		},
		clearComissioner: (state, action) => {
			state.isLoading = false
			state.commissionerList = null
		},
		clearStates: (state, action) => {
			state.isLoading = false
			state.changed = null
			state.edited = null
		},
		changePasswordSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Пароль успешно изменен' : null
		}
	}
})

export default usersSlice.reducer