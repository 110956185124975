import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/RePaymentMethods/getByName', values),
	add     : (values) => requests.post('/RePaymentMethods/createPaymentMethods', values),
	update  : (values) => requests.post('/RePaymentMethods/updatePaymentMethods', values),
	delete  : (values) => requests.post('/RePaymentMethods/deletePaymentMethods', values),
	restore : (values) => requests.post('/RePaymentMethods/restorePaymentMethods', values)
}
export default api