import {
	requests
}from "../../../api/agent"

const api = {
	get       : (values) => requests.getWithParams('/ReOrg/getAll', values),
	getByName : (values) => requests.getWithParams('/ReOrg/getReOrgByNameOrUNP', values),

	add     : (values) => requests.post('/ReOrg/createOrg', values),
	update  : (values) => requests.post('/ReOrg/updateOrg', values),
	delete  : (values) => requests.post('/ReOrg/deleteOrg', values),
	restore : (values) => requests.post('/ReOrg/restoreOrg', values),

	getOrgStatusType : () => requests.get('/ReOrganizationStatusType/getAll'),
	getOrgType       : () => requests.get('/ReOrganizationType/getAll')
}
export default api