import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from "../components/Loading/Global"

const LoadableReport = Loadable({
	loader  : () => import('../containers/Report'),
	loading : () => <Loading />
})

export const reportLoadable = {
	LoadableReport
}