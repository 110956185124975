import React, {
	useEffect
}from 'react'
import {
	getPageReAdministrativeTerritories
}from './actions'
import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'

const reAdministrativeTerritories = () => {
	const dispatch = useDispatch()
	const { reAdministrativeTerritoriesList, isLoading } = useSelector(state => state.reAdministrativeTerritoriesReducer)

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	const columns = [
		{
			title     : 'СОАТО',
			inputType : 'text',
			dataIndex : 'soato',
			editable  : false,
			key       : 'code',
			width     : 100
		},
		{
			title     : 'Название',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name'
		},
		{
			title     : 'Тип населенного пункта',
			inputType : 'text',
			dataIndex : 'reLocalityType',
			editable  : false,
			key       : 'reLocalityType',
			render    : (text, record) => {
                	return record.reLocalityType.name
			}
		},
		{
			title     : 'Область',
			inputType : 'text',
			dataIndex : 'reRegion',
			editable  : false,
			key       : 'reRegion',
			render    : (text, record) => {
				return record.reRegion.name
			}
		},
		{
			title     : 'Район',
			inputType : 'text',
			dataIndex : 'reDistrict',
			editable  : false,
			key       : 'reDistrict',
			render    : (text, record) => {
				return record.reDistrict.name
			}
		}
	]
	const onPaginationChange= (page) => {
		dispatch(getPageReAdministrativeTerritories({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				columns={ columns }
				dataSource={ reAdministrativeTerritoriesList }
				isEditable={ false }
				isLoading={ isLoading }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reAdministrativeTerritories