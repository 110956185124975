import React, {
	useEffect
}from "react"
import {
	Switch, Route
}from "react-router-dom"
import {
	homeLoadables
}from "./loadables"
import NotFound from "./components/NotFound"
import FirstAuthorization from './containers/Users/FirstAuth'
import {
	useSelector, useDispatch
}from "react-redux"
import {
	userActions
}from "./store/actions"
import NSI from "./containers/NSI"
import {
	accountLoadables
}from "./loadables/account"
import {
	managmentLoadable
}from "./loadables/managment"
import {
	documentLoadable
}from "./loadables/document"
import {
	messagesLoadable
}from "./loadables/messages"
import {
	reportLoadable
}from "./loadables/report"
import {
	AisMVLoadable
}from "./loadables/aismv"
const Routes = () => {
	const dispatch = useDispatch()
	const { user, roles } = useSelector((state) => state.userReducer)

	useEffect(() => {
		if (user === undefined)
			dispatch(userActions.refreshUserData())
	}, [dispatch])

	return (
		<Switch>

			{ (user?.isFirstEnter) &&
			<Route
				exact
				component={ FirstAuthorization }
				path="*"
			/> }

			{ (!user?.isFirstEnter) &&
					<Route
						exact
						component={ homeLoadables.LoadableHome }
						path="/"
					/> }

			{ 	((!user?.isFirstEnter) && roles.length != 0) && (
				<Route
					exact
					component={ accountLoadables.LoadableAccount }
					path="/account"
				/>
			) }
			{ (!user?.isFirstEnter) &&
			 <Route
			 	exact
			 	component={ homeLoadables.LoadableLogin }
			 	path="/login"
			 /> }

			{ (!user?.isFirstEnter) &&
			<Route
				exact
				component={ homeLoadables.LoadableSms }
				path="/login/smsConfirm"
			/> }

			{ ((!user?.isFirstEnter) && roles.length != 0) && (
				<Route
					exact
					component={ NSI }
					path="/nsi"
				/>
			) }

			{ (!user?.isFirstEnter) &&
			<Route
				exact
				component={ homeLoadables.LoadableHelpList }
				path="/helpList"
			/> }

			{ (!user?.isFirstEnter) &&
			<Route
				exact
				component={ messagesLoadable.LoadableHelpArticle }
				path="/helpArticle/:id/:page"
			/> }
			{ ((!roles.includes('registeredUser')) && roles.length > 0 && (!user?.isFirstEnter)) &&
					<Route
						exact
						component={ homeLoadables.LoadableRegister }
						path="/register"
					/> }

			{ ((!roles.includes('registeredUser')) && roles.length > 0 && (!user?.isFirstEnter)) && <Route
				exact
				component={ managmentLoadable.LoadableUsers }
				path="/users"
			                                                                                        /> }

			{ (roles.length > 0 && (!user?.isFirstEnter)) && <Route
				exact
				component={ managmentLoadable.LoadableOneUser }
				path="/users/:id"
			                                                 /> }

			{ (roles.length > 0 && (!user?.isFirstEnter)) && <Route
				exact
				component={ documentLoadable.LoadableDocumentView }
				path="/documents/:id"
			                                                 /> }

			{ (roles.length > 0 && (!user?.isFirstEnter)) && <Route
				exact
				component={ documentLoadable.LoadableDocuments }
				path="/documents"
			                                                 /> }

			{ (roles.length > 0 && (!user?.isFirstEnter)) && <Route
				exact
				component={ messagesLoadable.LoadableMessages }
				path="/messages"
			                                                 /> }

			{ ((!roles.includes('registeredUser')) && (!user?.isFirstEnter)) && <Route
				exact
				component={ reportLoadable.LoadableReport }
				path="/reports"
			                                                                    /> }
			{ (roles.includes('administratorSystem') && (!user?.isFirstEnter)) && <Route
				exact
				component={ messagesLoadable.LoadableHelpAdmin }
				path="/help"
			                                                                      /> }
			{ (roles.includes('administratorSystem') && (!user?.isFirstEnter)) && <Route
				exact
				component={ messagesLoadable.LoadableFullQuestion }
				path="/help/:id"
			                                                                      /> }
			{ (roles.includes('administratorSystem') && (!user?.isFirstEnter)) && <Route
				exact
				component={ messagesLoadable.LoadableAdministration }
				path="/administration"
			                                                                      /> }
			{ ((roles.includes('operatorISEWORD') || roles.includes('authorizedUnit')) && (!user?.isFirstEnter)) && <Route
				exact
				component={ AisMVLoadable.LoadableAisMVMessage }
				path="/AISMVMessage"
			                                                                                                        /> }
			{ ((roles.includes('operatorISEWORD')) && (!user?.isFirstEnter)) && <Route
				exact
				component={ AisMVLoadable.LoadableAisMVNotDirected }
				path="/AisMVNotDirected"
			                                                                    /> }

			<Route
				exact
				component={ NotFound }
				path="*"
			/>
		</Switch>
	)
}
export default Routes
