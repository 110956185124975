import React, {
	useEffect
}from 'react'

import {
	getPageRePaymentMethods,
	addRePaymentMethods,
	updateRePaymentMethods,
	deleteRePaymentMethods,
	restoreRePaymentMethods
}from './actions'

import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const rePaymentMethods = () => {
	const dispatch = useDispatch()
	const { rePaymentMethodsList, isLoading, changed } = useSelector(state => state.rePaymentMethodsReducer)
	const getDate=(text, record) => {
		return text ? moment(text).format('DD-MM-YYYY'):''
	}

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	useEffect(() => {
		if (changed)
			onPaginationChange(1)
	}, [changed])

	const columns = [
		{
			title     : 'Код',
			inputType : 'text',
			dataIndex : 'code',
			editable  : false,
			key       : 'code',
			width     : 100
		},
		{
			title     : 'Название',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name'
		},
		{
			title     : 'Название на английском',
			inputType : 'text',
			dataIndex : 'nameEn',
			editable  : false,
			key       : 'nameEn'
		},
		{
			title     : 'Дата начала действия',
			inputType : 'text',
			dataIndex : 'startDate',
			editable  : false,
			key       : 'startDate',
			render    : getDate
		},
		{
			title     : 'Дата окончания действия',
			inputType : 'text',
			dataIndex : 'endDate',
			editable  : false,
			key       : 'endDate',
			render    : getDate
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageRePaymentMethods({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				addItem={ addRePaymentMethods }
				changed={ changed }
				columns={ columns }
				dataSource={ rePaymentMethodsList }
				deleteItem={ deleteRePaymentMethods }
				isLoading={ isLoading }
				nameTable=" способ оплаты"
				restoreItem={ restoreRePaymentMethods }
				updateItem={ updateRePaymentMethods }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default rePaymentMethods