import React, {
	useEffect
}from 'react'

import {
	getPageReAddresses
}from './actions'

import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reAddresses = () => {
	const dispatch = useDispatch()
	const { reAddressesList, isLoading } = useSelector(state => state.reAddressesReducer)

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	const getRegion=(text, record) => {
		return `${ record.reATerNumber.reAdministrativeTerritory.reLocalityType.nameShort }${ record.reATerNumber.reAdministrativeTerritory.name }`
		+ `${ ", " }${ record.reATerNumber.reAdministrativeTerritory.reDistrict.name }${ " р-н., " }`
		+ `${ record.reATerNumber.reAdministrativeTerritory.reRegion.name }`
	}

	const getAddresType=(text, record) => {
		return `${ record.reAddressType.nameShort } ${ text }`
	}

	const columns = [
		{
			title     : 'Код',
			inputType : 'text',
			dataIndex : 'code',
			editable  : false,
			key       : 'code',
			width     : 100
		},
		{
			title     : 'Название',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name',
			render    : getAddresType
		},
		{
			title     : 'Регион',
			inputType : 'text',
			dataIndex : 'region',
			editable  : false,
			key       : 'region',
			render    : getRegion
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReAddresses({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				columns={ columns }
				dataSource={ reAddressesList }
				isEditable={ false }
				isLoading={ isLoading }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reAddresses