import React from "react"

export const editIcon = (onClick) => {
	return (
		<svg
			fill="black"
			height="16"
			viewBox="0 0 16 16"
			width="16"
			xmlns="http://www.w3.org/2000/svg"
			onClick={ onClick }
		>
			<path
				d="M15.3334 3.0651C15.3334 3.38021 15.2737 3.68576 15.1544 3.98177C15.035 4.27778 14.8608 4.54036 14.6316 4.76953L5.19995 14.2012L0.666748 15.3327L1.79826 10.7995L11.2299 1.36784C11.4591 1.13867 11.7217 0.96441 12.0177 0.845052C12.3137 0.725694 12.6192 0.666016 12.9343 0.666016C13.2638 0.666016 13.5741 0.728082 13.8653 0.852214C14.1565 0.976345 14.4096 1.14822 14.6244 1.36784C14.8393 1.58746 15.0111 1.84288 15.1401 2.13411C15.269 2.42535 15.3334 2.73568 15.3334 3.0651ZM3.00138 10.8926C3.50746 11.0597 3.94192 11.3247 4.30477 11.6875C4.66762 12.0503 4.93498 12.4872 5.10685 12.998L12.8484 5.24935L10.7501 3.15104L3.00138 10.8926ZM1.92716 14.0723L4.28328 13.485C4.23554 13.2654 4.15915 13.0577 4.05412 12.862C3.94908 12.6662 3.81779 12.492 3.66024 12.3392C3.50269 12.1864 3.32842 12.0575 3.13745 11.9525C2.94648 11.8474 2.7388 11.7687 2.5144 11.7161L1.92716 14.0723ZM13.5001 4.59766C13.6194 4.4783 13.734 4.3661 13.8438 4.26107C13.9536 4.15603 14.0515 4.04622 14.1375 3.93164C14.2234 3.81706 14.2902 3.69054 14.338 3.55208C14.3857 3.41363 14.412 3.25369 14.4167 3.07227C14.4167 2.86697 14.3786 2.676 14.3022 2.49935C14.2258 2.3227 14.1184 2.16515 13.9799 2.02669C13.8414 1.88824 13.6839 1.78082 13.5072 1.70443C13.3306 1.62804 13.1372 1.58746 12.9272 1.58268C12.7457 1.58268 12.5882 1.60655 12.4545 1.6543C12.3208 1.70204 12.1943 1.77127 12.075 1.86198C11.9556 1.95269 11.8434 2.05056 11.7384 2.1556C11.6333 2.26063 11.5211 2.37522 11.4018 2.49935L13.5001 4.59766Z"
			/>
		</svg>
	)
}

export const deleteIcon = (<svg
	height="12"
	viewBox="0 0 12 12"
	width="12"
	xmlns="http://www.w3.org/2000/svg"
	onClick={ (e) => {
		e.stopPropagation()
	} }
                           >
	<path d="M0.088588 0.215749L0.146448 0.146509C0.320008 -0.0270611 0.589438 -0.0463409 0.784308 0.088649L0.853548 0.146509L6 5.29306L11.1464 0.146509C11.32 -0.0270611 11.5894 -0.0463409 11.7843 0.088649L11.8536 0.146509C12.0271 0.320069 12.0464 0.589499 11.9114 0.784369L11.8536 0.853609L6.707 6.00006L11.8536 11.1465C12.0271 11.3201 12.0464 11.5895 11.9114 11.7844L11.8536 11.8537C11.68 12.0272 11.4106 12.0465 11.2157 11.9115L11.1464 11.8537L6 6.70706L0.853548 11.8537C0.679988 12.0272 0.410558 12.0465 0.215688 11.9115L0.146448 11.8537C-0.0271221 11.6801 -0.046402 11.4107 0.088588 11.2158L0.146448 11.1465L5.293 6.00006L0.146448 0.853609C-0.0271221 0.680049 -0.046402 0.410619 0.088588 0.215749L0.146448 0.146509L0.088588 0.215749Z" />
</svg>)

export const restoreIcon = (<svg
	height="14"
	viewBox="0 0 15 14"
	width="15"
	xmlns="http://www.w3.org/2000/svg"
	onClick={ (e) => {
		e.stopPropagation()
	} }
                            >
	<path
		clip-rule="evenodd"
		d="M10.8734 12.8332H14.0016V14H9.91779L9.33439 13.4166V9.33274H10.5012V11.6664C11.547 10.8817 12.2989 9.7685 12.6363 8.50535C12.9737 7.2422 12.877 5.90233 12.3618 4.7007C11.8466 3.49907 10.9426 2.50533 9.79503 1.87892C8.64743 1.25251 7.32267 1.02973 6.03331 1.24633C4.74394 1.46294 3.5647 2.10636 2.68478 3.07338C1.80486 4.04039 1.27526 5.27495 1.18096 6.57898C1.08665 7.88301 1.4331 9.18092 2.16473 10.2645C2.89635 11.348 3.97074 12.1544 5.21553 12.5543L4.86315 13.6675C3.35256 13.1831 2.05126 12.2 1.17244 10.8794C0.293606 9.55871 -0.110646 7.97874 0.025904 6.39828C0.162454 4.81782 0.831711 3.33059 1.92403 2.18024C3.01636 1.02988 4.46698 0.284604 6.03826 0.0664978C7.60954 -0.151608 9.20831 0.170389 10.5727 0.97974C11.937 1.78909 12.986 3.03781 13.5479 4.52132C14.1097 6.00483 14.1511 7.63518 13.6652 9.14528C13.1793 10.6554 12.195 11.9557 10.8734 12.8332V12.8332Z"
		fill-rule="evenodd"
	/>
</svg>)