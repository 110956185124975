import {
	createSlice
}from "@reduxjs/toolkit"

export const ReportReducer = createSlice({
    name: 'ReportReducer',
    initialState: {
        error: null,
        isLoading: false,
        reportsStats: null
    },
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = true
        },
        setError: (state, action) => {
            state.isLoading = false,
            state.error = action.payload
        },
        getStatsSuccess: (state, action) => {
            state.reportsStats = action.payload,
            state.isLoading = false
        } 
    }
}) 

export default ReportReducer.reducer