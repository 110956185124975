import {
	createSlice
}from "@reduxjs/toolkit"

export const reCountriesSlice = createSlice({
	name         : "reCountries",
	initialState : {
		isLoading       : false,
		reCountriesList : null,
		changed         : false,
		error           : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reCountriesList = action.payload
		},
		addSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно добавлена' : null
		},
		updateSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно изменена' : null
		},
		deleteSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно удалена' : null
		},
		restoreSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно восстановлена' : null
		},
		clear: (state, action) => {
			state.reCountriesList = null,
			state.changed = false
		}
	}
})

export default reCountriesSlice.reducer