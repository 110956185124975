import {
	createSlice
}from "@reduxjs/toolkit"

export const reRegionsSlice = createSlice({
	name         : "reRegions",
	initialState : {
		isLoading     : false,
		reRegionsList : null,
		changed       : false,
		error         : null
	},
	reducers: {
		clear: (state, action) => {
			state.reRegionsList = null,
			state.changed = false
		},
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reRegionsList = action.payload
		}
	}
})

export default reRegionsSlice.reducer