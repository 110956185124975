import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	usersSlice
}from "./reducer"

const { setLoading, setError, getUsersSuccess, responseSuccsessBlockUser, responseSuccsessUnblockUser, responseSuccsessUser,
	 getOrgUsersSuccess, clearUser, editSuccess, changePasswordSuccsess, clearComissioner,
	 getCommissionerSuccsess, clearStates, clearOrgUsers, getUserHistoryListSuccess } = usersSlice.actions

export const clearOrgUser = () => {
	const dispatchObj = {
		req: clearOrgUsers
	}
	return defActionSlice(dispatchObj)
}

export const getUsers = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : getUsersSuccess,
		service : {
			func   : api.getUsers,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const getUserHistoryList = (values) => {
	const dispatchObj = {
		req: setLoading,

		//fail    : setError,
		suc     : getUserHistoryListSuccess,
		service : {
			func   : api.getUserHistoryList,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const editUser = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : editSuccess,
		service : {
			func   : api.editUser,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const getListUsers = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : getUsersSuccess,
		service : {
			func   : api.getListUsers,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}
export const getUsersOrgs = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : getOrgUsersSuccess,
		service : {
			func   : api.getUsersOrglist,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const blockUser = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : responseSuccsessBlockUser,
		service : {
			func   : api.blockUser,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}
export const changePass = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : changePasswordSuccsess,
		service : {
			func   : api.changePassword,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}
export const getCommissioner = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : getCommissionerSuccsess,
		service : {
			func   : api.getCommissionerUsersList,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const unblockUser = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : responseSuccsessUnblockUser,
		service : {
			func   : api.unblockUser,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}

export const getUser = (values) => {
	const dispatchObj = {
		req     : setLoading,
		fail    : setError,
		suc     : responseSuccsessUser,
		service : {
			func   : api.getUser,
			params : values
		}
	}
	return defActionSlice(dispatchObj)
}
export const clearOneUser = () => {
	const dispatchObj = {
		req: clearUser
	}
	return defActionSlice(dispatchObj)
}
export const clearComissionerList = () => {
	const dispatchObj = {
		req: clearComissioner
	}
	return defActionSlice(dispatchObj)
}
export const clearState = () => {
	const dispatchObj = {
		req: clearStates
	}
	return defActionSlice(dispatchObj)
}