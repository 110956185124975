import reOrg from "./ReOrg"
import reAbonentsAISMV from "./ReAbonentsAISMV"
import reOfficialPersons from "./ReOfficialPersons"
export default [

	{
		render : reOrg,
		text   : 'Справочник юридических лиц и индивидуальных предпринимателей'
	},

	{
		render : reOfficialPersons,
		text   : 'Справочник ”Должностных лиц“'
	},
	{
		render : reAbonentsAISMV,
		text   : 'Справочник абонентов АИС МВ'
	}
]