import {
	createSlice
}from "@reduxjs/toolkit"

export const reAddressesSlice = createSlice({
	name         : "reAddresses",
	initialState : {
		isLoading        : false,
		reAddressesList  : null,
		reAddressesListd : null,
		changed          : false,
		error            : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reAddressesList = action.payload
		},
		getPagedSuccessd: (state, action) => {
			state.isLoading = false
			state.reAddressesListd = action.payload
		},
		clear: (state, action) => {
			state.reAddressesList = null,
			state.reAddressesListd = null,
			state.changed = false
		}
	}
})

export default reAddressesSlice.reducer