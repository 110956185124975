import {
	createSlice
}from "@reduxjs/toolkit"

export const rePaymentMethodsSlice = createSlice({
	name         : "rePaymentMethods",
	initialState : {
		isLoading            : false,
		rePaymentMethodsList : null,
		changed              : false,
		error                : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
			state.changed = false
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.rePaymentMethodsList = action.payload
		},
		addSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно добавлена' : null
		},
		updateSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно изменена' : null
		},
		deleteSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно удалена' : null
		},
		restoreSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно восстановлена' : null
		}
	}
})

export default rePaymentMethodsSlice.reducer