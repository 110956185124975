import {
	createSlice
}from "@reduxjs/toolkit"

export const reLocalityTypesSlice = createSlice({
	name         : "reLocalityTypes",
	initialState : {
		isLoading           : false,
		reLocalityTypesList : null,
		changed             : false,
		error               : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reLocalityTypesList = action.payload
		}
	}
})

export default reLocalityTypesSlice.reducer