import React, {
	useEffect
}from 'react'

import {
	getPageReDistricts
}from './actions'
import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reDistricts = () => {
	const dispatch = useDispatch()
	const { reDistrictsList, isLoading } = useSelector(state => state.reDistrictsReducer)

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	const columns = [
		{
			title     : 'Код',
			inputType : 'text',
			dataIndex : 'code',
			editable  : false,
			key       : 'code',
			width     : 100

		},
		{
			title     : 'Название',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name'
		},
		{
			title     : 'Код региона',
			inputType : 'text',
			dataIndex : 'regionCode',
			editable  : false,
			key       : 'regionCode'
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReDistricts({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				columns={ columns }
				dataSource={ reDistrictsList }
				isEditable={ false }
				isLoading={ isLoading }
				onPaginationChange={ onPaginationChange }
			></NSITable>

		</div>
	</>
	)
}

export default reDistricts