import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReFileTypes/getByName', values),
	add     : (values) => requests.post('/ReFileTypes/createFileTypes', values),
	update  : (values) => requests.post('/ReFileTypes/updateFileTypes', values),
	delete  : (values) => requests.post('/ReFileTypes/deleteFileTypes', values),
	restore : (values) => requests.post('/ReFileTypes/restoreFileTypes', values)
}
export default api