import React, { useEffect, useState } from "react"
import { editIcon } from "../NSITable/SVG"
import './style.css'
import { Upload } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { clearChangedAction, downloadScaleAction, replaceScaleAction } from "./store/actions"
import Notifications from "../../../helpers/Notifications"
import downloadFile from "../../../helpers/downloadFile"

const ReScales = () => {
    const dispatch = useDispatch()
    const { changed, download, isLoading } = useSelector(state => state.reScaleReducer)
    const [code, setCode] = useState(null)


    useEffect(() => {
        if (changed) {
            Notifications.successNotice(changed, '', 10)
            dispatch(clearChangedAction())
        }
    }, [changed])

    useEffect(() => {
        if (download)
            downloadFile(download, getFileName())
    }, [download])

    const getFileName = () => {
        let fileName = ''
        switch (code) {
            case "01":
                fileName = 'Предписание'
                break
            case "02":
                fileName = 'Уведомление'
                break

            case "03":
                fileName = 'Сообщение'
                break

            default:
                fileName = 'Сообщение без пароля'
                break

        }
        return {
            fileName, fileExtension: '.docx'
        }
    }

    const createFile = () => {
        return (event) => {
            if (event?.file) {
                const formData = new FormData()
                formData.append('NewFile', event.file)
                formData.append('Code', code)
                dispatch(replaceScaleAction(formData))
            }
        }


    }

    return (<div className="scales__wrapper">
        <div className="scales__item">
            <p onClick={() => {
                if (!isLoading) {
                    dispatch(downloadScaleAction({ code: '01' }))
                    setCode('01')
                }
            }}
            >Предписание</p>
            <Upload
                name="prescription__file"
                accept=".docx"
                onChange={createFile('01')}
                showUploadList={false}
                maxCount={1}
                customRequest={null}
                beforeUpload={() => false}
                disabled={isLoading}

            >
                {editIcon()}
            </Upload>
        </div>
        <div className="scales__item">
            <p onClick={() => {
                if (!isLoading) {
                    dispatch(downloadScaleAction({ code: '03' }))
                    setCode("03")
                }
            }}
            >Сообщение</p>
            <Upload
                name="message__file"
                accept=".docx"
                onChange={createFile('03')}
                showUploadList={false}
                maxCount={1}
                customRequest={null}
                beforeUpload={() => false}
                disabled={isLoading}

            >
                {editIcon()}
            </Upload>

        </div>
        <div className="scales__item">
            <p onClick={() => {
                if (!isLoading) {
                    dispatch(downloadScaleAction({ code: '04' }))
                    setCode("04")
                }
            }}
            >Сообщение без пароля</p>
            <Upload
                name="messageWP__file"
                accept=".docx"
                onChange={createFile('04')}
                showUploadList={false}
                maxCount={1}
                customRequest={null}
                beforeUpload={() => false}
                disabled={isLoading}

            >
                {editIcon()}
            </Upload>

        </div>
        <div className="scales__item">
            <p onClick={() => {
                if (!isLoading) {
                    dispatch(downloadScaleAction({ code: '02' }))
                    setCode('02')
                }
            }}
            >Уведомление</p>


            <Upload
                name="notif__file"
                accept=".docx"
                onChange={createFile('02')}
                showUploadList={false}
                maxCount={1}
                customRequest={null}
                beforeUpload={() => false}
                disabled={isLoading}
            >
                {editIcon()}
            </Upload>

        </div>
    </div >)
}

export default ReScales