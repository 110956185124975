import {
	createSlice
}from "@reduxjs/toolkit"

export const reOrgSlice = createSlice({
	name         : "reOrg",
	initialState : {
		isLoading     : false,
		ReOrg         : null,
		changed       : false,
		error         : null,
		UNPList       : null,
		OrgStatusType : null,
		OrgType       : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reOrgList = action.payload
		},
		getByUNPSuccess: (state, action) => {
			state.isLoading = false
			state.UNPList = action.payload
		},
		clearOrgs: (state, action) => {
			state.isLoading = false
			state.UNPList = null
		},
		getOrgStatusTypeSuccess: (state, action) => {
			state.isLoading = false
			state.OrgStatusType = action.payload
		},
		getOrgTypeSuccess: (state, action) => {
			state.isLoading = false
			state.OrgType = action.payload
		},
		addSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно добавлена' : null
		},
		updateSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно изменена' : null
		},
		deleteSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно удалена' : null
		},
		restoreSuccess: (state, action) => {
			state.changed = action.payload ? 'Запись успешно восстановлена' : null
		}
	}
})

export default reOrgSlice.reducer