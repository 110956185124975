import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reFileTypesSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, addSuccess, updateSuccess, deleteSuccess, restoreSuccess } = reFileTypesSlice.actions

export const getPageReFileTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const addReFileTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.add,
			params : values
		},
		suc  : addSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const updateReFileTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.update,
			params : values
		},
		suc  : updateSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const deleteReFileTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.delete,
			params : values
		},
		suc  : deleteSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const restoreReFileTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.restore,
			params : values
		},
		suc  : restoreSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}