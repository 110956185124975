import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reAddressTypesSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess } = reAddressTypesSlice.actions

export const getPageReAddressTypes = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}