export const userConstants = {
	Login: {
		REQUEST : "USERS_LOGIN_REQUEST",
		SUCCESS : "USERS_LOGIN_SUCCESS",
		FAILURE : "USERS_LOGIN_FAILURE"
	},
	LoginCode: {
		REQUEST : "USERS_LOGIN_REQUEST",
		SUCCESS : "USERS_LOGIN_SUCCESS",
		FAILURE : "USERS_LOGIN_FAILURE"
	},
	Register: {
		REQUEST : "REGISTER_REQUEST",
		SUCCESS : "REGISTER_SUCCESS",
		FAILURE : "REGISTER_FAILURE"
	},
	Logout: {
		REQUEST : "USERS_LOGOUT_REQUEST",
		SUCCESS : "USERS_LOGOUT_SUCCESS",
		FAILURE : "USERS_LOGOUT_FAILURE"
	},
	RefreshUserData: {
		REQUEST : "REFRESH_USER_DATA_REQUEST",
		SUCCESS : "REFRESH_USER_DATA_SUCCESS",
		FAILURE : "REFRESH_USER_DATA_FAILURE"
	},
	clearFirstEnter: {
		CLEAR: "CLEAR_FIRST_ENTER"
	}
}
