import reOrg from "./ReOrg"
import reAuthorizedUnits from "./ReAuthorizedUnits"
import reAdministrativeTerritories from "./ReAdministrativeTerritories"
import reLocalityTypes from "./ReLocalityTypes"
import reRegions from "./ReRegions"
import reDistricts from "./ReDistricts"
import reAddresses from "./ReAddresses"
import reAddressTypes from "./ReAddressTypes"
import reIndex from "./ReIndex"
import reTypesNotifications from "./ReTypesNotifications"
import reTypesDocuments from "./ReTypesDocuments"
import rePaymentMethods from "./RePaymentMethods"
import reNotificationMethod from "./ReNotificationMethod"
import reFileTypes from "./ReFileTypes"
import reTypesDocumentsSED from "./ReTypesDocumentsSED"
import reCountries from "./ReCountries"
import reAbonentsAISMV from "./ReAbonentsAISMV"
import reOfficialPersons from "./ReOfficialPersons"
import ReScales from "./ReScales"
export default [

	{
		render : reOrg,
		text   : 'Справочник юридических лиц и индивидуальных предпринимателей'
	},
	{
		render : ReScales,
		text   : 'Шаблоны'
	},

	// {
	// 	render : reAuthorizedUnits,
	// 	text   : 'Справочник ”Уполномоченные подразделения“'
	// },
	{
		render : reOfficialPersons,
		text   : 'Справочник ”Должностных лиц“'
	},
	{
		render : reCountries,
		text   : 'Справочник стран'
	},
	{
		render : reAdministrativeTerritories,
		text   : 'Классификатор ”Система  обозначений  объектов  административно-территориального деления и населенных пунктов“'
	},
	{
	 	render : reLocalityTypes,
		text   : 'Типы населенных пунктов'
	},
	{
		render : reRegions,
		text   : 'Справочник областей'
	},
	{
	   render : reDistricts,
	   text   : 'Cправочник районов'
	},
	{
		render : reAddresses,
		text   : 'Справочник улиц'
	},
	{
		render : reAddressTypes,
		text   : 'Типы улиц'
	},
	{
		render : reIndex,
		text   : 'Справочник индексов'
	},
	{
		render : reTypesNotifications,
		text   : 'Справочник типов уведомлений'
	},
	{
		render : reNotificationMethod,
		text   : 'Справочник способов уведомлений'
	},
	{
		render : reTypesDocuments,
		text   : 'Справочник типов документов ИСЭВОРД'
	},
	{
		render : reAbonentsAISMV,
		text   : 'Справочник абонентов АИС МВ'
	},
	{
		render : rePaymentMethods,
		text   : 'Справочник способов оплаты'
	},
	{
		render : reFileTypes,
		text   : 'Справочник типов файлов'
	},
	{
		render : reTypesDocumentsSED,
		text   : 'Справочник видов документов, допустимых для использования в СЭД'
	}
]