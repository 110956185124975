import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reTypesDocumentsSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, addSuccess, updateSuccess, deleteSuccess,getAllSuccess, restoreSuccess } = reTypesDocumentsSlice.actions

export const getPageReTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.getByName,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}
export const getAllTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getAllSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const addReTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.add,
			params : values
		},
		suc  : addSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const updateReTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.update,
			params : values
		},
		suc  : updateSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const deleteReTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.delete,
			params : values
		},
		suc  : deleteSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const restoreReTypesDocuments = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.restore,
			params : values
		},
		suc  : restoreSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}