import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReTypesNotifications/getByName', values),
	add     : (values) => requests.post('/ReTypesNotifications/createTypesNotifications', values),
	update  : (values) => requests.post('/ReTypesNotifications/updateTypesNotifications', values),
	delete  : (values) => requests.post('/ReTypesNotifications/deleteTypesNotifications', values),
	restore : (values) => requests.post('/ReTypesNotifications/restoreTypesNotifications', values)
}
export default api