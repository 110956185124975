import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReAuthorizedUnits/getByName', values),
	add     : (values) => requests.post('/ReAuthorizedUnits/createAuthorizedUnit', values),
	update  : (values) => requests.post('/ReAuthorizedUnits/updateAuthorizedUnit', values),
	delete  : (values) => requests.post('/ReAuthorizedUnits/deleteAuthorizedUnit', values),
	restore : (values) => requests.post('/ReAuthorizedUnits/restoreAuthorizedUnit', values)
}
export default api