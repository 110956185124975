import {
	createSlice
}from "@reduxjs/toolkit"

export const userTypeSlice = createSlice({
	name         : "userType",
	initialState : {
		isLoading : false,
		typeList  : null,
		changed   : false,
		error     : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.typeList = action.payload
		},
		getSuccess: (state, action) => {
			state.isLoading = false
			state.typeList = action.payload
		},
		clearType: (state, action) => {
			state.isLoading = false
			state.typeList = null
		},
		clear: (state, action) => {
			state.typeList = null,
			state.changed = false
		}
	}
})

export default userTypeSlice.reducer