import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReNotificationMethod/getByName', values),
	add     : (values) => requests.post('/ReNotificationMethod/createNotificationMethod', values),
	update  : (values) => requests.post('/ReNotificationMethod/updateNotificationMethod', values),
	delete  : (values) => requests.post('/ReNotificationMethod/deleteNotificationMethod', values),
	restore : (values) => requests.post('/ReNotificationMethod/restoreNotificationMethod', values)
}
export default api