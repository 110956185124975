import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reIndexSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, clear } = reIndexSlice.actions

export const getPageReIndex = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const clearIndex = () => {
	const dispatchObj = {
		req: clear
	}
	return defActionSlice(dispatchObj)
}