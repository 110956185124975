import {
	createSlice
}from "@reduxjs/toolkit"

export const ReScalesSlicer = createSlice({
	name         : 'ReScaleSlice',
	initialState : {
		isLoading : false,
		error     : null,
		changed   : null,
		download  : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.isLoading = false,
			state.error = action.payload
		},
		replaceScaleSuccess: (state, action) => {
			state.isLoading = false,
			state.changed = action.payload ? 'Шаблон успешно изменён!' : null
		},
		clearChanged: (state, action) => {
			state.changed = null
		},
		downloadScale: (state, action) => {
			state.isLoading = false
			state.download = action.payload
		}
	}
})

export default ReScalesSlicer.reducer