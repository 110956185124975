import React, {
	useEffect
}from "react"
import ChangePassword from "../OneUser/ChangePassword"
import {
	useSelector
}from "react-redux"
import history from "../../../helpers/history"
import "../OneUser/style.css"
import "../style.css"
const FirstAuthorization = () => {
	const { user } = useSelector(state => state.userReducer)
	useEffect(() => {
		if (!user?.isFirstEnter)
			history.push('/account')
	 }, [user])

	return	(
		<div className="first-auth-wrapper">
			<ChangePassword
				id={ user.id }
			/>
		</div>
	)
}

export default FirstAuthorization