import {
	request, success, failure,
}from './dispatchFunctions'

export const defAction = ({
	constants,
	service = null,
	reqParams = null,
	sucParams = null,
	sucFunction = null,
	sucFunctionDis = null,
	failParams = null
}) => {
	return (dispatch) => {
		dispatch(request(constants.REQUEST, reqParams))

		if (service){
			service
				.func(service.params)
				.then((res) => {
					if (sucFunction)
						sucFunction(res)

					if (sucFunctionDis)
						dispatch(sucFunctionDis)

					dispatch(success(constants.SUCCESS, {
						sucParams,
						result: res
					}))
				})
				.catch((err) => {
					dispatch(failure(constants.FAILURE, {
						failParams,
						err
					}))
				})
		}
		else
			dispatch(success(constants.SUCCESS))
	}
}

export const defActionSlice = ({
	req,
	reqParams,
	service,
	suc,
	fail
}) => {
	return (dispatch) => {
		dispatch(req(reqParams))
		if(service?.func) {
			service.func(service.params)
			.then((res) => {
				dispatch(suc(res))
			})
			.catch((err) => {
				dispatch(fail({
					err
				}))
			})
		}
	}

}

export const defActionAlert = ({
	constant, reqParams = null
}) => {
	return (dispatch) => {
		dispatch(request(constant, reqParams))
	}
}
