import {
	createSlice
}from "@reduxjs/toolkit"

export const reAddressTypesSlice = createSlice({
	name         : "reAddressTypes",
	initialState : {
		isLoading          : false,
		reAddressTypesList : null,
		changed            : false,
		error              : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reAddressTypesList = action.payload
		}
	}
})

export default reAddressTypesSlice.reducer