import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reDistrictsSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, getPagedSuccessd, clear } = reDistrictsSlice.actions

export const getPageReDistricts = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}
export const getPageReDistrictsDouble = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccessd,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const clearDistricts = () => {
	const dispatchObj = {
		req: clear
	}
	return defActionSlice(dispatchObj)
}