import { defActionSlice } from "../../../../helpers/defaultAction"
import api from "./api"
import { ReScalesSlicer } from "./reducer"

const { setLoading, setError, replaceScaleSuccess, clearChanged, downloadScale} = ReScalesSlicer.actions

export const replaceScaleAction = (values) => {
    const defObj = {
        req: setLoading,
        suc: replaceScaleSuccess,
        fail: setError,
        service: {
            params: values,
            func: api.replaceScaleFile
        }
    }

    return defActionSlice(defObj)
}
export const downloadScaleAction = (values) => {
    const defObj = {
        req: setLoading,
        suc: downloadScale,
        fail: setError,
        service: {
            params: values,
            func: api.downloadScaleFile
        }
    }

    return defActionSlice(defObj)
}

export const clearChangedAction = () => {
    const defObj = {
        req: clearChanged
    }

    return defActionSlice(defObj)
} 