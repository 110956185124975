import {
	combineReducers
}from "redux"
import globalReducer from "../../containers/Menu/reducer"
import userReducer from "./user"
import alertReducer from './alertReducer'
import {
	routerReducer
}from "react-router-redux"
import usersReducer from "../../containers/Users/store/reducer"
import reAuthorizedUnitsReducer from "../../containers/NSI/ReAuthorizedUnits/reducer"
import reAdministrativeTerritoriesReducer from "../../containers/NSI/ReAdministrativeTerritories/reducer"
import reOrgReducer from "../../containers/NSI/ReOrg/reducer"
import reLocalityTypesReducer from "../../containers/NSI/ReLocalityTypes/reducer"
import reRegionsReducer from "../../containers/NSI/ReRegions/reducer"
import reDistrictsReducer from "../../containers/NSI/ReDistricts/reducer"
import reAddressesReducer from "../../containers/NSI/ReAddresses/reducer"
import reAddressTypesReducer from "../../containers/NSI/ReAddressTypes/reducer"
import reIndexReducer from "../../containers/NSI/ReIndex/reducer"
import reTypesNotificationsReducer from "../../containers/NSI/ReTypesNotifications/reducer"
import reTypesDocumentsReducer from "../../containers/NSI/ReTypesDocuments/reducer"
import rePaymentMethodsReducer from "../../containers/NSI/RePaymentMethods/reducer"
import reTypesDocumentsSEDReducer from "../../containers/NSI/ReTypesDocumentsSED/reducer"
import reNotificationMethodReducer from "../../containers/NSI/ReNotificationMethod/reducer"
import reFileTypesReducer from "../../containers/NSI/ReFileTypes/reducer"
import reCountriesReducer from "../../containers/NSI/ReCountries/reducer"
import reAbonentsAISMVReducer from "../../containers/NSI/ReAbonentsAISMV/reducer"
import documentsReducer from "../../containers/Documents/store/reducer"
import userTypeReducer from "../../containers/NSI/userTypes/reducer"
import notificationsReducer from "../../containers/Account/Notifications/store/reducer"
import messagesReducer from "../../containers/Messages/store/reducer"
import commonQuestionsReducer from "../../containers/CommonQuestions/store/reducer"
import AisMVReducer from "../../containers/AisMV/store/reducer"
import ReportReducer from "../../containers/Report/store/reducer"
import reOfficialPersonsReducer from "../../containers/NSI/ReOfficialPersons/reducer"
import reScaleReducer from "../../containers/NSI/ReScales/store/reducer"

export default combineReducers({
	alertReducer,
	globalReducer,
	router: routerReducer,
	userReducer,
	userTypeReducer,
	reOrgReducer,
	usersReducer,
	reAuthorizedUnitsReducer,
	reAdministrativeTerritoriesReducer,
	reLocalityTypesReducer,
	reRegionsReducer,
	reDistrictsReducer,
	reAddressesReducer,
	reAddressTypesReducer,
	reIndexReducer,
	reTypesNotificationsReducer,
	reTypesDocumentsReducer,
	rePaymentMethodsReducer,
	reTypesDocumentsSEDReducer,
	reNotificationMethodReducer,
	reFileTypesReducer,
	reCountriesReducer,
	reAbonentsAISMVReducer,
	documentsReducer,
	notificationsReducer,
	messagesReducer,
	commonQuestionsReducer,
	AisMVReducer,
	ReportReducer,
	reOfficialPersonsReducer,
	reScaleReducer
})
