import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reOrgSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, getByUNPSuccess,
	 getOrgStatusTypeSuccess, getOrgTypeSuccess, addSuccess,
	  updateSuccess, deleteSuccess, restoreSuccess, clearOrgs } = reOrgSlice.actions

export const getPageReOrg = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}
export const getByUNP = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.getByName,
			params : values
		},
		suc  : getByUNPSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}
export const clearOrg = () => {
	const dispatchObj = {
		req: clearOrgs
	}
	return defActionSlice(dispatchObj)
}
export const createOrg = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.add,
			params : values
		},
		suc  : addSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const updateOrg = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.update,
			params : values
		},
		suc  : updateSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const deleteOrg = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.delete,
			params : values
		},
		suc  : deleteSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const restoreOrg = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.restore,
			params : values
		},
		suc  : restoreSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const getOrgStatusType = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.getOrgStatusType,
			params : values
		},
		suc  : getOrgStatusTypeSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const getOrgType = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.getOrgType,
			params : values
		},
		suc  : getOrgTypeSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}