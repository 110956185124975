import React, {
	useEffect
}from 'react'

import {
	getPageReCountries,
	addReCountries,
	updateReCountries,
	deleteReCountries,
	restoreReCountries
}from './actions'

import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reCountries = () => {
	const dispatch = useDispatch()
	const { reCountriesList, isLoading, changed } = useSelector(state => state.reCountriesReducer)

	useEffect(() => {
		onPaginationChange(1)
	}, [])

	useEffect(() => {
		if (changed)
			onPaginationChange(1)
	}, [changed])

	const columns = [
		{
			title     : 'Код',
			inputType : 'text',
			dataIndex : 'code',
			editable  : false,
			key       : 'code',
			width     : 100
		},
		{
			title     : 'Код ИСО',
			inputType : 'text',
			dataIndex : 'codeIso',
			editable  : false,
			key       : 'codeIso'
		},
		{
			title     : 'Название',
			inputType : 'text',
			dataIndex : 'name',
			editable  : false,
			key       : 'name'
		},
		{
			title     : 'Название на английском',
			inputType : 'text',
			dataIndex : 'nameEn',
			editable  : false,
			key       : 'nameEn'
		}
	]

	const onPaginationChange= (page) => {
		const values = {
			id: 1
		}
		values.page = 10

		dispatch(getPageReCountries({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				addItem={ addReCountries }
				changed={ changed }
				columns={ columns }
				dataSource={ reCountriesList }
				deleteItem={ deleteReCountries }
				isEditable={ false }
				isLoading={ isLoading }
				restoreItem={ restoreReCountries }
				updateItem={ updateReCountries }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reCountries