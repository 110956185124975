import {
	createSlice
}from "@reduxjs/toolkit"

export const reAdministrativeTerritoriesSlice = createSlice({
	name         : "reAdministrativeTerritories",
	initialState : {
		isLoading                        : false,
		reAdministrativeTerritoriesList  : null,
		reAdministrativeTerritoriesListd : null,
		changed                          : false,
		error                            : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true
		},
		setError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		getPagedSuccess: (state, action) => {
			state.isLoading = false
			state.reAdministrativeTerritoriesList = action.payload
		},
		getPagedSuccessd: (state, action) => {
			state.isLoading = false
			state.reAdministrativeTerritoriesListd = action.payload
		},
		clear: (state, action) => {
			state.reAdministrativeTerritoriesList = null,
			state.reAdministrativeTerritoriesListd = null,
			state.changed = false
		}
	}
})

export default reAdministrativeTerritoriesSlice.reducer