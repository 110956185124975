import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reRegionsSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, clear } = reRegionsSlice.actions

export const getPageReRegions = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const clearReRegion = () => {
	const dispatchObj = {
		req: clear
	}
	return defActionSlice(dispatchObj)
}