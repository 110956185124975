import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reAdministrativeTerritoriesSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, getPagedSuccessd, clear } = reAdministrativeTerritoriesSlice.actions

export const getPageReAdministrativeTerritories = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}
export const getPageReAdministrativeTerritoriesDouble = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccessd,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const clearAdministrativeTerritories = () => {
	const dispatchObj = {
		req: clear
	}
	return defActionSlice(dispatchObj)
}