import {
	Button,
	Form, Input
}from "antd"
import React, {
	useEffect
}from "react"
import {
	useDispatch, useSelector
}from "react-redux"
import {
	changePass
}from "../store/actions"
import randomIcon from '../../../../public/Random.svg'
import "./style.css"
import Notifications from "../../../helpers/Notifications"
import {
	userActions
}from "../../../store/actions"
const ChangePassword = ({ id }) => {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const { roles } = useSelector(state => state.userReducer)
	const { changed } = useSelector(state => state.usersReducer)
	const genPassword =() => {
		let password = ""
		const bigSymbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
		const numbers = '0123456789'
		const symbols = 'abcdefghijklmnopqrstuvwxyz'
		for (let i = 0; i < 3; i++)
			password += symbols.charAt(Math.floor(Math.random() * symbols.length)) + numbers.charAt(Math.floor(Math.random() * numbers.length)) + bigSymbols.charAt(Math.floor(Math.random() * bigSymbols.length))
		form.setFieldsValue({
			NewPassword: password
		})
	}
	const editPassword = (values) => {
		dispatch(changePass({ ...values,
			userId: id }))
	}

	useEffect(() => {
		if (changed){
			dispatch(userActions.refreshUserData())
			Notifications.successNotice(changed, '', 10)
			form.resetFields()
		}
	 }, [changed])

	return (
		<Form
			className="password-window"
			form={ form }
			labelAlign="left"
			labelCol={ { span: 5 } }
			onFinish={ editPassword }
		>
			{ !roles?.includes('administratorSystem') ?
				(<Form.Item
					label="Старый пароль"
					name="OldPassword"
					normalize={ (value) => (value ? value.trim() : value) }
					rules={ [
						{
							pattern    : /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z#$^+=!*()@%&]{8,}$/,
							message    : 'Пароль должен содержать минимум 8 символов, содержать латинские символы в верхнем и нижнем регистре',
						        required   : true,
						        whitespace : true
						}
					  ] }
				>
					<Input.Password
						autoComplete="new-password"
						maxLength={ 30 }
						size="large"
						type="password"
					>

					</Input.Password>
				</Form.Item>)
				:null }
			<Form.Item
				label="Новый пароль"
				name="NewPassword"
				normalize={ (value) => (value ? value.trim() : value) }
				rules={ [
					{
						pattern    : /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z#$^+=!*()@%&]{8,}$/,
						message    : 'Пароль должен содержать минимум 8 символов, содержать латинские символы в верхнем и нижнем регистре',
						        required   : true,
						        whitespace : true
					},
					({ getFieldValue }) => ({
						validator(_, value){
							if (getFieldValue('OldPassword') === value)
								return Promise.reject(new Error('Новый пароль не отличается от старого!'))
							return Promise.resolve()
						}
					})
					  ] }
			>
				<Input.Password
					autoComplete="new-password"
					maxLength={ 30 }
					prefix={ (<img
						alt="generate"
						className="randomize-icon"
						src={ randomIcon }
						onClick={ genPassword }
					          />) }
					size="large"
					type="password"
				>

				</Input.Password>
			</Form.Item>
			<Form.Item
				hasFeedback
				dependencies={ ['password'] }
				label="Подтвердите пароль"
				name="confirm"
				rules={ [
					{
						required : true,
						message  : 'Подтвердите ваш пароль'
					},
					({ getFieldValue }) => ({
						validator(_, value){
							if (!value || getFieldValue('NewPassword') === value)
								return Promise.resolve()

							return Promise.reject(new Error('Новый пароль не совпадает!'))
						}
					})
				] }
			>
				<Input.Password
					maxLength={ 30 }
					size="large"
				>

				</Input.Password>
			</Form.Item>
			<Button
				className="register_button"
				htmlType="submit"
				size="large"
				type="primary"
			>
Сохранить изменения
			</Button>
		</Form>
	)
}
export default ChangePassword