import {
	requests
}from '../../../api/agent'

const api = {
	getUser                  : (values) => requests.getWithParams('/ManagmentUsers/getUser', values),
	getUsers                 : (values) => requests.getWithParams('/ManagmentUsers/getUsers', values),
	getListUsers             : (values) => requests.getWithParams('/ManagmentUsers/getListUsers', values),
	getUsersOrglist          : (values) => requests.getWithParams('/ManagmentUsers/GetUsersOrgList', values),
	changePassword           : (values) => requests.post('/Password/changePassword', values),
	blockUser                : (values) => requests.post('/ManagmentUsers/blockUser', values),
	unblockUser              : (values) => requests.post('/ManagmentUsers/unblockUser', values),
	editUser                 : (values) => requests.post('/ManagmentUsers/сhangeUser', values),
	getCommissionerUsersList : (values) => requests.getWithParams('/ManagmentUsers/GetCommissionerUsersList', values),
	getUserHistoryList 	     : (values) => requests.getWithParams('/ManagmentUsers/GetUserHistoryList', values)
}

export default api