import React, {
	useEffect
}from 'react'

import {
	getPageReOrg, createOrg, updateOrg,
	deleteOrg, restoreOrg, getOrgStatusType,
	getOrgType
}from './actions'
import NSITable from '../NSITable'
import {
	useDispatch, useSelector
}from 'react-redux'
import moment from 'moment'

const reOrg = () => {
	const dispatch = useDispatch()
	const { reOrgList, isLoading, OrgType, OrgStatusType, changed } = useSelector(state => state.reOrgReducer)
	const getDate=(text, record) => {
		return text ? moment(text).format('DD-MM-YYYY'):''
	}

	useEffect(() => {
		dispatch(getOrgStatusType())
		dispatch(getOrgType())
		onPaginationChange(1)
	}, [])

	useEffect(() => {
		if (changed)
			dispatch(getOrgStatusType())
		dispatch(getOrgType())
		onPaginationChange(1)
	}, [changed])

	const columns = [
		{
			title     : 'УНП',
			inputType : 'text',
			dataIndex : 'unp',
			editable  : false,
			key       : 'unp',
			width     : 100
		},
		{
			title     : 'Статус',
			inputType : 'text',
			dataIndex : 'reOrganizationStatusTypeId',
			editable  : false,
			isSelect  : true,
			values    : OrgStatusType,
			key       : 'reOrganizationStatusTypeId',
			render    : (text, record) => {
				return record.reOrganizationStatusType.name
			}
		},
		{
			title     : 'Полное название',
			inputType : 'text',
			dataIndex : 'fullName',
			editable  : false,
			key       : 'fullName'
		},
		{
			title     : 'Сокращенное название',
			inputType : 'text',
			dataIndex : 'shortName',
			editable  : false,
			key       : 'shortName'
		},
		{
			title     : 'Тип',
			inputType : 'text',
			dataIndex : 'reOrganizationTypeId',
			editable  : false,
			key       : 'reOrganizationTypeId',
			isSelect  : true,
			values    : OrgType,
			render    : (text, record) => {
				return record.reOrganizationType.name
			}
		},
		{
			title     : 'Дата начала действия',
			inputType : 'text',
			dataIndex : 'dateCreate',
			editable  : false,
			key       : 'dateCreate',
			render    : getDate
		},
		{
			title     : 'Дата изменения',
			inputType : 'text',
			dataIndex : 'dateUpdate',
			editable  : false,
			key       : 'dateUpdate',
			render    : getDate
		}
	]

	const onPaginationChange= (page) => {
		dispatch(getPageReOrg({ pageSize : 10,
			page     : page,
			name     : '' }))
	}

	return (<>
		<div>
			<NSITable
				addItem={ createOrg }
				changed={ changed }
				columns={ columns }
				dataSource={ reOrgList }
				deleteItem={ deleteOrg }
				isLoading={ isLoading }
				nameTable=" юридическое лицо или индивидуального предпринимателя"
				restoreItem={ restoreOrg }
				updateItem={ updateOrg }
				onPaginationChange={ onPaginationChange }
			></NSITable>
		</div>
	</>
	)
}

export default reOrg