import {
	defActionSlice
}from "../../../helpers/defaultAction"
import api from "./api"
import {
	reNotificationMethodSlice
}from "./reducer"

const { setLoading, setError, getPagedSuccess, addSuccess, updateSuccess, deleteSuccess, restoreSuccess } = reNotificationMethodSlice.actions

export const getPageReNotificationMethod = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.get,
			params : values
		},
		suc  : getPagedSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const addReNotificationMethod = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.add,
			params : values
		},
		suc  : addSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const updateReNotificationMethod = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.update,
			params : values
		},
		suc  : updateSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const deleteReNotificationMethod = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.delete,
			params : values
		},
		suc  : deleteSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}

export const restoreReNotificationMethod = (values) => {
	const dispatchObj = {
		req     : setLoading,
		service : {

			func   : api.restore,
			params : values
		},
		suc  : restoreSuccess,
		fail : setError
	}
	return defActionSlice(dispatchObj)
}