import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'

const LoadableHome = Loadable({
	loader  : () => import('../containers/Home'),
	loading : () => <Loading />
})

const LoadableLogin = Loadable({
	loader  : () => import('../containers/Login'),
	loading : () => <Loading />
})
const LoadableSms = Loadable({
	loader  : () => import('../containers/Login/Sms'),
	loading : () => <Loading />
})

const LoadableRegister = Loadable({
	loader  : () => import('../containers/Register'),
	loading : () => <Loading />
})

export const homeLoadables = {
	LoadableHome,
	LoadableLogin,
	LoadableRegister,
	LoadableSms
}
