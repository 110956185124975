import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from "../components/Loading/Global"

const LoadableMessages = Loadable({
    loader: () => import('../containers/Messages'),
    loading: () => <Loading />
})

const LoadableHelpAdmin= Loadable({
	loader  : () => import("../containers/Messages/Help"),
	loading : () => <Loading />
})

const LoadableHelpArticle = Loadable({
	loader  : () => import("../components/HomeFooter/HelpArticle"),
	loading : () => <Loading />
})

const LoadableAdministration = Loadable({
	loader  : () => import("../containers/CommonQuestions"),
	loading : () => <Loading />
})


export const messagesLoadable = {
    LoadableMessages,
    LoadableHelpAdmin,
    LoadableHelpArticle,
    LoadableAdministration
}