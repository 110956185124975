import {
	requests
}from "../../../api/agent"

const api = {
	get     : (values) => requests.getWithParams('/ReOfficialPersons/getAll', values),
	add     : (values) => requests.post('/ReOfficialPersons/createOfficialPerson', values),
	update  : (values) => requests.post('/ReOfficialPersons/updateOfficialPerson', values),
	delete  : (values) => requests.post('/ReOfficialPersons/deleteOfficialPerson', values),
	restore : (values) => requests.post('/ReOfficialPersons/restoreOfficialPerson', values)
}
export default api